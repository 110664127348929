<script setup>
import {defineAsyncComponent, onMounted} from "vue"

const GameList = defineAsyncComponent(() => import("@shared/components/molecules/GameList.vue"))
const GamesNavigation = defineAsyncComponent(() => import("@shared/components/molecules/GamesNavigation.vue"))

import PromotionsBanner from "@shared/components/molecules/PromotionsBanner.vue"
import {useGameStore} from "@shared/stores/GameStore"

const gameStore = useGameStore()

onMounted(function() {
    gameStore.fetchGames()

})
</script>

<template>
    <div id="gamesPage">

        <GamesNavigation />

        <div id="homePage" style="background-color: unset;">
            <PromotionsBanner section="homepage" />
        </div>

        <div id="webplay">
            <div class="wrapper">
                <div class="gamesblock">
                    <h2>MOST POPULAR</h2>
                    <GameList
                        group="mostPopular"
                        :url="{name: 'webplay.all'}"
                        url-text="ALL GAMES"
                        :promotions-url="{name: 'promotions'}"
                        :display-amounts="{sm: 10, md: 9, lg: 8, xl: 10}"
                        show-more-on-click
                        :show-more-custom-amount="30"
                    />
                </div>

                <div class="gamesblock">
                    <h2>FEATURED GAMES</h2>
                    <GameList
                        group="Featured"
                        :url="{name: 'webplay.all'}"
                        url-text="ALL GAMES"
                        :promotions-url="{name: 'promotions'}"
                        :display-amounts="{sm: 10, md: 9, lg: 8, xl: 10}"
                        show-more-on-click
                        :show-more-custom-amount="30"
                    />
                </div>

                <div class="gamesblock">
                    <h2>LIVE CASINO</h2>
                    <GameList
                        group="Lives"
                        :url="{name: 'webplay.live-casino'}"
                        :display-amounts="{sm: 10, md: 9, lg: 8, xl: 10}"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
